import axios, { AxiosRequestConfig } from 'axios';
import { getAuthToken, mediaApiScope } from '../azure/auth';
import { MediaDetailModel } from './MediaApiTypes';
import * as urls from './MediaUrlBuilder';

export async function uploadMedia(
    url: string,
    input: FormData,
    requestConfig: AxiosRequestConfig = {}
): Promise<MediaDetailModel> {
    const token = await getAuthToken([mediaApiScope]);
    const resp = await axios.post<MediaDetailModel>(url, input, {
        headers: {
            'Content-Type': 'multipart/form-data',
            authorization: `bearer ${token}`,
        },
        ...requestConfig,
    });
    return resp.data;
}

export async function deleteProfileImages(url: string): Promise<void> {
    const token = await getAuthToken([mediaApiScope]);
    const resp = await axios.delete(url, { headers: { authorization: `bearer ${token}` } });
    return resp.data;
}

export async function deleteMemberHeroImage(clientId: string, accountId: string, mediaId: string): Promise<void> {
    const token = await getAuthToken([mediaApiScope]);
    const url = `${urls.teamMemberHeroUpload(clientId, accountId)}/${mediaId}`;
    const resp = await axios.delete(url, { headers: { authorization: `bearer ${token}` } });
    return resp.data;
}

export async function activityContentDelete(clientId: string, accountId: string, mediaId: string): Promise<void> {
    const token = await getAuthToken([mediaApiScope]);
    const resp = await axios.delete(urls.activityContentDelete(clientId, accountId, mediaId), {
        headers: {
            'Content-Type': 'multipart/form-data',
            authorization: `bearer ${token}`,
        },
    });
    return resp.data;
}
